body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

html,
body {
	font-size: 0.8rem; /* 0.75rem */
}

#webpack-dev-server-client-overlay {
	width: 50px !important;
	height: 50px !important;
	top: 100px !important;
	left: 100px !important;
}

.image-edit:hover,
.image-edit-icon:hover + .image-edit {
	opacity: 0.5;
	cursor: pointer;
}

.image-edit-icon:hover,
.image-edit:hover + .image-edit-icon {
	display: block;
	cursor: pointer;
}

.image-edit-icon {
	display: none;
}
